<template>
  <div>
    <!-- Si es admin muestra botones -->
    <template v-if="admin && envioCorreos">

        <v-tooltip right v-if="correoEnviado">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="green darken-3" icon  class="ma-1"  :disabled="loadingCorreosLocal" @click="enviarCorreo" ><v-icon>mdi-email-check</v-icon></v-btn>
          </template>
          <span>Reenviar</span>
        </v-tooltip>
        <v-tooltip right v-else>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="grey"
              icon
              class="ma-1"
              :disabled="loadingCorreosLocal"
              @click="enviarCorreo"
              ><v-icon>mdi-email</v-icon></v-btn
            >
          </template>
          <span>Enviar</span>
        </v-tooltip>

      
     
        
  
    </template>
    <!-- Si es otro muestra el enlace -->
    <template v-else-if="!admin">
      <template v-if="correoEnviado">
        <v-dialog transition="dialog-top-transition" width="600"
          ><template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              color="green darken-3"
              icon
              class="ma-1"
              v-bind="attrs"
              ><v-icon>mdi-email-check</v-icon></v-btn
            > </template
          ><template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark dense>Enlace votación</v-toolbar>
              <v-card-text>
                <h4 v-if="enlaceVotacion" style="margin-top: 20px">
                  {{ enlaceVotacion }}
                </h4>
                <h4 v-else style="margin-top: 20px">
                  No se ha asignado un enlace de votación.
                </h4>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="primary" small dark @click="dialog.value = false"
                  >Cerrar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template></v-dialog
        >
      </template>
      <template v-else>
        <v-dialog transition="dialog-top-transition" width="600" height="200"
          ><template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" color="grey" icon class="ma-1" v-bind="attrs"
              ><v-icon>mdi-email</v-icon></v-btn
            > </template
          ><template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark dense>Enlace votación</v-toolbar>
              <v-card-text>
                <h4 v-if="enlaceVotacion" style="margin-top: 20px">
                  {{ enlaceVotacion }}
                </h4>
                <h4 v-else style="margin-top: 20px">
                  No se ha asignado un enlace de votación.
                </h4>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="primary" small dark @click="dialog.value = false"
                  >Cerrar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template></v-dialog
        >
      </template>
    </template>
    <template v-if="!envioCorreos">
      Envio de correos inactivo
    </template>
  </div>
</template>

<script>
export default {
  name: "botonesEnviarCorreo",
  props: {
    loadingCorreos: { type: Boolean, default: () => false },
    admin: { type: Boolean, default: () => false },
    correoEnviado: { type: Boolean, default: () => false },
    enlaceVotacion: { type: String, default: () => "" },
    envioCorreos:{type:Boolean,default:()=>false}
  },
  data() {
    return {
      loadingCorreosLocal: false,
    }
  },
  created() {
    this.loadingCorreosLocal = this.loadingCorreos;
  },
  watch: {
    loadingCorreos(value) {
      this.loadingCorreosLocal = value;
    }
  },
  methods: {
    enviarCorreo() {
      this.$emit("enviarCorreo");
    },
  },
};
</script>

