<template>
  <v-dialog v-model="mostrar" max-width="800" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Escaneo de QR</h3>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12" style="padding-bottom: 1px">
            <v-text-field
              id="scanQR"
              v-model="token"
              name="SG-token"
              label="QR"
              type="text"
              :loading="loading"
              color="blue-grey"
              outlined
              dense
              @keyup.enter="agregarAsistencia()"
              @keydown.tab.prevent=""
             
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" style="padding-top: 1px">
            <v-card flat class="scroll">
              <div v-if="error" class="error-text">{{ error }}</div>
              <v-card-title style="color:#202945" v-if="mensaje">¡Bienvenido!</v-card-title>
              
              <!-- <v-row v-if="registrandoAsistencia">
                cargando asistencia
                <v.card>
                <v-progress-linear
                  color="deep-purple-accent-4"
                  height="6"
                  indeterminate
                  rounded
                ></v-progress-linear>
              </v-row> -->
              <v-row v-if="correoEnviado" style="align-items:center;">
                <h3 style="font-weight:bold; font-size:12px; color:#202945; padding-left:30px">Revisa la bandeja de</h3>
                <h6 style="font-weight:bold; font-size:18px; color:green; padding-left:10px; padding-right:10px">{{correoEnviado}}</h6> 
                <h3 style="font-weight:bold; font-size:12px; color:#202945;">para consultar tu liga de votación</h3>
              </v-row>
              <h6 style="color:orange" v-if="correoNoEnviado"> {{correoNoEnviado}}</h6>
              <v-card-subtitle style="color:green" v-if="mensaje">Asistencia registrada</v-card-subtitle>
                <v-list>
                <v-list-item>
                 <v-list-item-content>
                    <v-list-item-title style="color:green"><strong>{{ qrData.nombre }}</strong></v-list-item-title>
                    <v-list-item-subtitle  v-if="qrData.dependencia != 'null'">{{ qrData.dependencia }}</v-list-item-subtitle>
                    <v-list-item-subtitle  v-if="qrData.divisionDepto != 'null'">{{ qrData.divisionDepto }}</v-list-item-subtitle>
                    <v-list-item-subtitle  v-if="qrData.categoria != 'null'">{{ qrData.categoria }}</v-list-item-subtitle>
                   
                 </v-list-item-content>
                </v-list-item>
                </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { agregarAsistenciaQRService } from "../administrar.service";
import { getConsejeroService } from "../../consejos/consejos.service";
import _ from "underscore";
export default {
  name: "agregarAsistenteQR",
  props: ["mostrar", "idSesion", "consejeros"],
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  data() {
    return {
      loading: false,
      token: null,
      mensaje: null,
      error: null,
      qrData: {},
      correoNoEnviado:null,
      correoEnviado:null,
      registrandoAsistencia:false,
    };
  },
  mounted(){
    this.activeFocus();
  },
  methods: {
    activeFocus(){
      let textField = document.getElementById('scanQR');
      textField.focus();
    },
    async agregarAsistencia() {
      try {
        this.loading = true;
        this.mensaje = null;
        this.error = null;
        this.correoEnviado=null; // agregué esto
        const sendData = this.validarQR();
        const datosUsuario = await getConsejeroService(sendData)
        this.qrData = datosUsuario.qrData;
        // this.registrandoAsistencia=true;
        const serverResponse = await agregarAsistenciaQRService(this.$route.params.idSesion, sendData);
        // if(serverResponse.asistencia.correoEnviado) this.correoEnviado=serverResponse.asistencia.correoEnviado.correo; // agregué esto 
        this.loading = false;
        // this.token = '';
  
        if (!serverResponse.ok) {
          this.token = '';
          this.activeFocus()
          this.error = serverResponse.mensaje;
        }
        else {
          this.token = '';
          const nombreUsuario = this.getNombreUsuario(serverResponse.asistencia);
          this.mensaje = nombreUsuario;
          if(serverResponse.asistencia.correoEnviado) this.correoEnviado=serverResponse.asistencia.correoEnviado.correo; // 
          this.correoNoEnviado=serverResponse.correoFallado;
          let emitData = { asistencia: _.omit(serverResponse.asistencia, 'correoEnviado' )};
          emitData.correoEnviado=serverResponse.asistencia.correoEnviado.ok;
          this.$emit("asistenciaAgregada", emitData);
          this.$notificarSuccess('Asistencia capturada correctamente')
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    validarQR() {
      const data = this.token.split(" - ");
      const codes = data[0].split("-");
      if (codes.length < 2) {
        this.token=''
        this.activeFocus()
        this.error = "Datos introducidos no válidos";
        throw new Error("Datos no válidos");
        
      }

      //TODO COMENTAR Y DESOMENTAR ESTO CUANDO SE USE UN QR DE UNA SESION EN OTRA
      if(this.$route.params.idSesion != codes[0]){
        this.token=''
        this.activeFocus()
        this.error = "El código QR no corresponde a la sesión actual";
        throw new Error("Código QR no corresponde a la sesión actual");
      }
      return {
        idSesion: codes[0],
        usuarioMaster: codes[1],
      };
    },
    getNombreUsuario(asistencia) {
      const consejero = this.consejeros.find((x) => {
        if (x.usuarioMaster._id == asistencia.usuarioMaster || x.suplente.usuarioMaster != null && x.suplente.usuarioMaster._id == asistencia.usuarioMaster) return x;
       });
      if (consejero.usuarioMaster._id == asistencia.usuarioMaster)
        return consejero.usuarioMaster.nombre;
      return consejero.suplente.usuarioMaster.nombre;
    },
  },
};
</script>

<style>
.avatar-item {
  margin-right: 15px !important;
}

.error-text {
  color: brown;
}
</style>
