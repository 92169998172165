<template>
  <div>
    <v-container>
      <v-card outlined :loading="loading">
        <v-toolbar>
          <v-toolbar-title>Citatorio</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :disabled="loading" style="margin-left: 10px" small outlined
            v-if="!invitacion || (!invitacion.texto && sePuedeEnviar)" @click="abrirFormulario = true">
            Agregar citatorio
          </v-btn>
          <v-btn :disabled="loading" style="margin-left: 10px" small outlined
            v-if="invitacion.texto && sePuedeEnviar && !correoEnviado" @click="abrirFormulario = true">
            Modificar citatorio
          </v-btn>
          <v-btn v-if="drive && invitacion.texto && sePuedeEnviar" :disabled="loading" style="margin-left: 10px" small
            outlined smal @click="loadingcorreos = true">
            Enviar citatorio a los consejeros
          </v-btn>
          <v-btn :disabled="loading" style="margin-left: 10px" small outlined  @click="correosCalendario = true"> <!-- v-if="invitacion.texto && sePuedeEnviar && !correoEnviado" -->
            Enviar correo a mantenimiento
          </v-btn>
         
        </v-toolbar>
        <v-toolbar color="#FBD9D3" v-if="fallidos">
          Algunos citatorios no se enviaron
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" text @click="descargarCorreos()" style="margin: 10px 0px 10px auto" :disabled="loading">
                <v-icon>mdi-download</v-icon>CORREOS FALLIDOS</v-btn>
            </template>
            <span>Descargar correos fallidos</span>
          </v-tooltip>
          <v-btn :disabled="loading" style="margin-left: 10px" small outlined smal @click="loadingFallidos = true">
            Intentar enviar citatorios
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row v-if="drive" style="justify-content: flex-end">
            <v-tooltip left v-if="valida">
              <template v-slot:activator="{ on }">
                <v-btn  v-on="on" text style="margin: 10px 0px 10px 0px; display: flex"
                  @click="probar = true" outlined color="orange">
                  <v-icon>mdi-wrench</v-icon>Enviar Pruebas</v-btn>
              </template>
              <span>Hacer pruebas de envio de correos</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text @click="descargar()" style="margin: 10px 0px 10px 10px; display: flex">
                  <v-icon>mdi-download</v-icon>ARCHIVO DE ENLACES</v-btn>
              </template>
              <span>Descargar ligas de drive</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-if="!correoEnviado" :disabled="loading" icon v-on="on" style="margin: 10px 10px 10px 0px"
                  @click="cargar = true"><v-icon>mdi-dots-vertical</v-icon></v-btn>
              </template>
              <span>Modificar archivo</span>
            </v-tooltip>
          </v-row>
          <v-row v-else style="justify-content: flex-end">
            <v-btn @click="cargar = true" text style="margin: 10px">
              <v-icon>mdi-upload</v-icon>CARGAR LIGAS DE DRIVE</v-btn>
          </v-row>

          <v-row style="justify-content: flex-end">
            <v-col cols="6">
              <v-card>
                <v-toolbar>
                  <h4 style="font-family: Roboto, sans-serif">
                    CON COPIA PARA
                  </h4>
                </v-toolbar>
                <v-list-item v-if="invitacion.cc1 && !modificarC1">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="loading" icon v-on="on" style="margin: 10px 10px 10px 0px"
                        @click="(modificarC1 = true), (copia1 = invitacion.cc1)"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                    </template>
                    <span>Modificar correo</span>
                  </v-tooltip>
                  {{ invitacion.cc1 }}
                </v-list-item>
                <v-container v-if="!invitacion.cc1 || modificarC1">
                  <v-row>
                    <v-col cols="9">
                      <v-text-field v-model="copia1" label="ejemplo@correo.com" :disabled="loading" outlined dense
                        :error-messages="copia1Errors" @input="$v.copia1.$touch()" @blur="$v.copia1.$touch()">
                      </v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-btn color="primary" icon :disabled="loading || copia1.length == 0" small
                        @click="guardarCopia(1, copia1)"><v-icon>mdi-check</v-icon></v-btn>
                    </v-col>
                    <v-col cols="1">
                      <v-btn color="primary" v-if="modificarC1" icon small
                        @click="modificarC1 = false"><v-icon>mdi-window-close</v-icon></v-btn>
                    </v-col>
                    <v-col cols="1">
                      <v-btn color="primary" v-if="modificarC1 || copia1.length != 0" small icon
                        @click="eliminar(1)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                <v-list-item v-if="invitacion.cc2 && !modificarC2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="loading" icon v-on="on" style="margin: 10px 10px 10px 0px"
                        @click="(modificarC2 = true), (copia2 = invitacion.cc2)"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                    </template>
                    <span>Modificar correo</span>
                  </v-tooltip>
                  {{ invitacion.cc2 }}
                </v-list-item>
                <v-container v-if="!invitacion.cc2 || modificarC2">
                  <v-row>
                    <v-col cols="9">
                      <v-text-field v-model="copia2" label="ejemplo@correo.com" :disabled="loading" outlined dense
                        :error-messages="copia2Errors" @input="$v.copia2.$touch()"
                        @blur="$v.copia2.$touch()"></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-btn color="primary" icon :disabled="loading || copia2.length == 0" small
                        @click="guardarCopia(2, copia2)"><v-icon>mdi-check</v-icon></v-btn>
                    </v-col>
                    <v-col cols="1">
                      <v-btn color="primary" v-if="modificarC2" small icon
                        @click="modificarC2 = false"><v-icon>mdi-window-close</v-icon></v-btn>
                    </v-col>
                    <v-col cols="1">
                      <v-btn color="primary" v-if="modificarC2 || copia2.length != 0" small icon
                        @click="eliminar(2)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="invitacion.texto">
            
            <v-container>
              <p style="font-size: 20px;">
                Asunto:
                <u style="font-weight: bolder">
                  {{ invitacion.asunto }}
                </u>
              </p>
              <div v-html="invitacion.texto"></div>
              <v-img v-if="invitacion.qr" width="30%" :src="`${httpURL}/sesion/invitacion/qr/${idSesion}/${usuarioMaster}`"></v-img>
              <div v-html="invitacion.despedida"></div>
            </v-container>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-dialog v-model="cargar" max-width="400" persistent>
      <v-card>
        <v-toolbar color="primary" dark dense flat>
          <h4>Cargar ligas de drive</h4>
        </v-toolbar>
        <v-container>
          <v-file-input label="Cargar archivo de enlaces de DRIVE" dense outlined :loading="loading" :disabled="loading" ref ="inputFile"
            :error-messages="fileError" persistent-hint @change="archivoSeleccionado()" v-model="file">
          </v-file-input>
        </v-container>
        <v-divider class="mx-3"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined :disabled="loading"
            @click="cerrarDialog()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="probar" max-width="50%" persistent>
      <v-card outlined :loading="loadingPruebas">
        <v-toolbar>
          <h4 style="color: #202945">Ventana de pruebas</h4>
        </v-toolbar>
        <v-container v-if="!correoPruebaEnviado">
          <v-row>
            <v-text-field :disabled="loadingPruebas" v-model="textFieldCorreo" style="margin: 10px 10px 10px 10px"
              label="Correo electronico" outlined clearable :error-messages="emailPruebaErrors"
              @input="$v.textFieldCorreo.$touch()" @blur="$v.textFieldCorreo.$touch()"></v-text-field>
            <v-btn color="primary" small :disabled="$v.textFieldCorreo.$error || !textFieldCorreo || loadingPruebas
              " @click="enviarInvitaciondePrueba()" style="margin: 15px 10px 10px 10px">Enviar</v-btn>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-toolbar color="warning" dark dense flat>
            <h4>
              Los correos de prueba de los siguientes consejeros han sido
              enviados a <u> {{ textFieldCorreo }}</u>
            </h4>
          </v-toolbar>
          <v-list-item v-for="prueba in arregloPrueba" :key="prueba.codigo" style="padding-left: 20px">
            <v-list-item-content>
              <v-list-item-title>
                {{ prueba.consejero }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ prueba.codigo }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ prueba.correo }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined :disabled="loadingPruebas" @click="
            (probar = false),
            (fileError = null),
            (arregloPrueba = []),
            (correoPruebaEnviado = false),
            (textFieldCorreo = '')
            ">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingFallidos" max-width="50%" persistent :loading="loadingEnvio">
      <v-card :loading="loadingEnvio">
        <template v-slot:loader="{ isActive }">
          <v-progress-linear :active="isActive" color="white" height="10" indeterminate></v-progress-linear>
        </template>
        <v-toolbar color="primary" dark dense>
          <h2>Enviar Correos</h2>
          <v-spacer></v-spacer>
          <div style="display: flex; justify-content: flex-end">
            <v-btn class="mr-2 mt-4 mb-5" :disabled="loadingEnvio" outlined @click="enviarInvitacionFallida()"
              style="display: flex; align-content: flex-end">Enviar Correos</v-btn>
            <v-btn class="mr-2 mt-4 mb-5" :disabled="loadingEnvio" outlined @click="loadingFallidos = false"
              style="display: flex; align-content: flex-end">Cancelar</v-btn>
          </div>
        </v-toolbar>
        <v-container>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Funcionario</th>
                  <th class="text-left">Correo</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(consejero, index) in arregloFallidos" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ consejero.consejero }}</td>
                  <td>{{ consejero.correo }}</td>
                  <td v-if="arregloEnviados.includes(consejero.usuarioMaster)">
                    <v-icon color="success">mdi-email-check-outline</v-icon>
                  </td>
                  <td v-else-if="loadingEnvio">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </td>
                  <td v-else>
                    <v-icon color="error">mdi-email-alert</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingcorreos" max-width="50%" persistent :loading="loadingEnvio">
      <v-card :loading="loadingEnvio">
        <template v-slot:loader="{ isActive }">
          <v-progress-linear :active="isActive" color="white" height="10" indeterminate></v-progress-linear>
        </template>
        <v-toolbar color="primary" dark dense>
          <h2>Enviar Correos</h2>
          <v-spacer></v-spacer>
          <div style="display: flex; justify-content: flex-end">
            <v-btn class="mr-2 mt-4 mb-5" :disabled="loadingEnvio" outlined @click="enviarInvitacion()"
              style="display: flex; align-content: flex-end">Enviar Correos</v-btn>
            <v-btn class="mr-2 mt-4 mb-5" :disabled="loadingEnvio" outlined @click="loadingcorreos = false"
              style="display: flex; align-content: flex-end">Cancelar</v-btn>
          </div>
        </v-toolbar>
        <v-container>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Funcionario</th>
                  <th class="text-left">Correo</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(consejero, index) in arregloConsejeros" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ consejero.consejero }}</td>
                  <td>{{ consejero.correo }}</td>
                  <td v-if="arregloFallidosMaster.includes(consejero.usuarioMaster)">
                    <v-icon color="error">mdi-email-alert</v-icon>
                  </td>
                  <td v-else-if="arregloEnviados.includes(consejero.usuarioMaster)">
                    <v-icon color="success">mdi-email-check-outline</v-icon>
                  </td>
                  <td v-else-if="loadingEnvio">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </td>
                  <td v-else>
                    <v-icon color="warning">mdi-email-alert-outline</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card>
    </v-dialog>

    <formulario-invitacion v-if="abrirFormulario" :mostrar="abrirFormulario" :invitacion="invitacion"
      :usuarioMaster="usuarioMaster" :qr="invitacion.qr" @cancelar="abrirFormulario = false"
      @invitacionGuardada="invitacionGuardada" />
    
    <correoCalendario v-if="correosCalendario" :mostrar="correosCalendario" @cancelar="correosCalendario = false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import formularioInvitacion from './formularioInvitacion.vue';
import correoCalendario from './correoCalendario.vue';
import { getInvitacionService, subirCSV, descargarLigas, descargarFallidos, guardarCopiaService, enviarCorreoPrueba,eliminarService,
  enviarCorreoService,
  enviarCorreoFallidoService} from "./invitaciones.service";
import { validationMixin } from "vuelidate";
import { email } from "vuelidate/lib/validators";
import { DateTime } from "luxon";
export default {
  name: "invitacionView",
  components: { formularioInvitacion, correoCalendario },
  props: {
    fecha: String,
    consejeros: Array,
  },
  mixins: [validationMixin],
  validations: {
    copia1: { email },
    copia2: { email },
    textFieldCorreo: { email },
  },

  computed: {
    ...mapGetters(["httpURL", "userAdmin", "sessionToken"]),
    sePuedeEnviar() {
      let fecha = DateTime.fromISO(this.fecha);
      fecha.setZone("America/Mexico_City");
      let now = new DateTime.now().setZone("America/Mexico_City");
      if (now > fecha || this.invitacionEnviada) return false;
      return true;
    },
    copia1Errors() {
      const errors = [];
      if (!this.$v.copia1.$dirty) return errors;
      !this.$v.copia1.email && errors.push("Correo no válido.");
      return errors;
    },
    copia2Errors() {
      const errors = [];
      if (!this.$v.copia2.$dirty) return errors;
      !this.$v.copia2.email && errors.push("Correo no válido.");
      return errors;
    },
    emailPruebaErrors() {
      const errors = [];
      if (!this.$v.textFieldCorreo.$dirty) return errors;
      !this.$v.textFieldCorreo.email && errors.push("Correo no válido.");
      return errors;
    },
    invitacionEnviada() {
      return (
        this.arregloEnviados.length + this.arregloFallidos.length >=
        this.arregloConsejeros.length
      );
    },
    correoEnviado() {
      return this.arregloEnviados.length ? true : false;
    },
    valida(){
      if(this.correoEnviado==false&&this.invitacion.texto!=null) return true
      else return false
    }
   
  },

  data() {
    return {
      probar: false,
      loading: false,
      abrirFormulario: false,
      correosCalendario: false,
      idSesion: null,
      invitacion: {
        texto: null,
        asunto: null,
        qr: false,
        despedida:null
      },
      usuarioMaster: "",
      fileError: null,
      drive: null,
      cargar: false,
      fallidos: false,
      copia1: "",
      copia2: "",
      modificarC1: false,
      modificarC2: false,
      textFieldCorreo: "",
      arregloPrueba: [],
      correoPruebaEnviado: false,
      loadingPruebas: false,
      loadingFallidos: false,
      loadingcorreos: false,
      loadingEnvio: false,
      porcentaje: 0,
      arregloConsejeros: [],
      arregloFallidos: [],
      arregloEnviados: [],
      arregloFallidosMaster: [],
      file:null
    };
  },
  mounted() {
    this.idSesion = this.$route.params.idSesion;
    this.getSesion();
  },
  methods: {
    async archivoSeleccionado() {
      if (this.file) {
        this.fileError = null;
        try {
          this.loading = true;
          if (this.file.type !== "text/csv")
            throw new Error("No se seleccionó un archivo CSV");
          const archivo = new FormData();
          archivo.append("file", this.file);
          const serverResponse = await subirCSV(archivo, this.idSesion);
          this.loading = false;
          this.getSesion();
          if (!serverResponse.ok) {
            this.fileError = serverResponse.mensaje;
            this.file=null
          }
          else this.cargar = false;
        } catch (error) {
          this.loading = false;
          this.fileError = error.message;
        }
      }
    },
    invitacionGuardada(invitacion) {

      this.invitacion.asunto = invitacion.asunto;
      this.invitacion.texto = invitacion.texto;
      this.invitacion.despedida = invitacion.despedida;
      this.invitacion.qr = invitacion.qr;
      this.invitacion.liga=invitacion.liga;

    },
  
    async getSesion() {
      try {
        this.loading = true;
        const serverResponse = await getInvitacionService(this.idSesion);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          if (serverResponse.sesion.invitacion) {
            this.invitacion = serverResponse.sesion.invitacion;
            this.arregloEnviados = serverResponse.sesion.invitacion.recibidaPor;
          }
          this.usuarioMaster = serverResponse.usuarioMaster;
          this.drive = serverResponse.sesion.drive;
          this.fallidos = serverResponse.sesion.fallidos;
          this.arregloConsejeros = serverResponse.consejeros;
          this.arregloFallidos = serverResponse.consejerosFallidos;
          this.arregloFallidosMaster = this.arregloFallidos.map((x) => x.usuarioMaster);

        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    async enviarInvitacion() {
      this.loadingEnvio = true;
      try {
        for (const index in this.arregloConsejeros) {
          if (
            !this.arregloEnviados.includes(
              this.arregloConsejeros[index].usuarioMaster
            ) &&
            !this.arregloFallidosMaster.includes(
              this.arregloConsejeros[index].usuarioMaster
            )
          ) {
            let serverResponse = await enviarCorreoService(
              this.idSesion,
              this.arregloConsejeros[index].usuarioMaster
            );
            if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
            else if (serverResponse.enviada)
              this.arregloEnviados.push(
                this.arregloConsejeros[index].usuarioMaster
              );
            else
              this.arregloFallidosMaster.push(this.arregloConsejeros[index].usuarioMaster)
          }
        }
        this.loadingcorreos = false;
        this.loadingEnvio = false;
        this.getSesion();
      } catch (error) {
        this.loadingcorreos = false;
        this.loadingEnvio = false;
        this.$notificarError(error);
      }
    },
    async enviarInvitacionFallida() {
      this.loadingEnvio = true;
      try {
        for (const index in this.arregloFallidos) {
          if (
            !this.arregloEnviados.includes(
              this.arregloFallidos[index].usuarioMaster
            )
          ) {
            let serverResponse = await enviarCorreoFallidoService(
              this.idSesion,
              this.arregloFallidos[index].usuarioMaster
            );
            if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
            else if (serverResponse.enviada) {
              this.arregloEnviados.push(
                this.arregloFallidos[index].usuarioMaster
              );
              this.arregloFallidosMaster.splice(
                this.arregloFallidos[index].usuarioMaster,
                1
              );
            }
          }
        }
        if (!this.arregloFallidosMaster) this.loadingFallidos = false;
        this.getSesion();
      } catch (error) {
        this.loadingEnvio = false;
        this.$notificarError(error);
      }
      this.loadingEnvio = false;
    },
    async enviarInvitaciondePrueba() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.textFieldCorreo != "") {
        try {
          this.arregloPrueba = [];
          this.loadingPruebas = true;
          const serverResponse = await enviarCorreoPrueba(this.idSesion, {
            correoPrueba: this.textFieldCorreo,
          });
          this.loadingPruebas = false;
          if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
          else {
            this.arregloPrueba = serverResponse.arregloConsejeros;
            this.correoPruebaEnviado = true;
          }
        } catch (error) {
          this.loadingPruebas = false;
          this.$notificarError(error);
          this.textFieldCorreo = "";
        }
      } else this.textFieldCorreo = "";
    },
    async descargar() {
      try {
        this.loading = true;
        const serverResponse = await descargarLigas(this.idSesion);
        this.loading = false;
        const url = window.URL.createObjectURL(new Blob([serverResponse]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Ligas de drive.csv");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    async descargarCorreos() {
      try {
        this.loading = true;
        const serverResponse = await descargarFallidos(this.idSesion);
        this.loading = false;
        const url = window.URL.createObjectURL(new Blob([serverResponse]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Correos no enviados.csv");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    async guardarCopia(index, cc) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          let no = index == 1 ? "cc1" : "cc2";

          const serverResponse = await guardarCopiaService(this.idSesion, {
            no,
            copia: cc,
          });
          this.loading = false;
          if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
          else {
            index == 1
              ? (this.invitacion.cc1 = serverResponse.cc)
              : (this.invitacion.cc2 = serverResponse.cc);
            index == 1
              ? (this.modificarC1 = false)
              : (this.modificarC2 = false);
          }
        } catch (error) {
          this.loading = false;
          this.$notificarError(error);
        }
      }
    },
    async eliminar(index) {
      try {
        this.loading = true;
        let no = index == 1 ? "cc1" : "cc2";
        const serverResponse = await eliminarService(this.idSesion, no);
        this.loading = false;
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        else {
          if (index == 1) {
            this.copia1 = "";
            this.invitacion.cc1 = null;
            this.modificarC1 = false;
          } else {
            this.copia2 = "";
            this.invitacion.cc2 = null;
            this.modificarC2 = false;
          }
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    cerrarDialog(){
      this.fileError=null
      this.cargar=false
      this.file = null
    }
  },
};
</script>
